<template lang="">
  <div>
    <location-view-info-cards :location-entry="locationEntry" />
    <!-- {{ locationEntry }} -->
    <b-row class="match-height">
      <b-col
        xl="4"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="primary"
          icon="BoxIcon"
          :statistic="locationEntry.storages.length"
          statistic-title="Lagereinheiten"
        />
      </b-col>
      <b-col
        xl="4"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="success"
          icon="ShoppingCartIcon"
          :statistic="countOfRentedStoragesinLocation(locationEntry).value"
          statistic-title="Vermietet"
        />
      </b-col>
      <b-col
        xl="4"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="danger"
          icon="SlashIcon"
          :statistic="countOfNotRentedStoragesinLocation(locationEntry).value"
          statistic-title="Nicht vermietet"
        />
      </b-col>
    </b-row>
    <div>
      <!-- {{ windowHeight }} -->
      <div class="d-flex flex-wrap">
        <div
          v-for="(storage, itemIndex) in getSortedStorages"
          :key="itemIndex"
          style="position: relative;"
        >
          <a :href="`/storages-view/${storage.id}`">
            <feather-icon
              style="position: absolute; z-index: 10; right:10; top:2; color:white;"
              icon="ExternalLinkIcon"
              size="20"
            />

            <b-card
              :id="`/storages-view/${storage.id}`"
              style="min-width:130px; max-width:130px; min-height:100px; max-height:100px;"
              class="mb-1 mr-50"
              :bg-variant="`${getVariant(storage)}`"
              text-variant="white"
            >
              <div class="">
                {{ itemIndex }}
                {{ storage.storageIdent }}
              </div>

              <small class="">{{ storage.storage_type.name }}</small>
            </b-card>
          </a>
          <b-popover
            ref="popover"
            :target="`/storages-view/${storage.id}`"
            triggers="hover"
            show.sync="popoverShow"
            placement="top"
            custom-class="wide-popover"
          >
            <b-card>
              <b-card-title><div>{{ storage.storageIdent }}</div>
                <small class="text-muted">{{
                  storage.storage_type.name
                }}</small></b-card-title>
              <dl style="min-width:250px;">
                <dl class="row">
                  <dt class="col-sm-5">
                    Name
                  </dt>
                  <dd class="col-sm-7">
                    {{ storage.name }}
                  </dd>
                </dl>
                <hr>
                <dl class="row">
                  <dt class="col-sm-5">
                    Lagerfäche
                  </dt>
                  <dd class="col-sm-7">
                    {{ storage.storageSpace }} m²
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-sm-5">
                    Volumen
                  </dt>
                  <dd class="col-sm-7">
                    {{ storage.storageVolume }} m³
                  </dd>
                </dl>
                <hr>
                <dt>Beschreibung</dt>
                <dd>{{ storage.description }}</dd>
              </dl>
            </b-card>
          </b-popover>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BCardSubTitle,
  BListGroup,
  BListGroupItem,
  BPopover,
  BBadge,
  BCardHeader,
  BCardBody,
} from 'bootstrap-vue'
import storageHelper from '@/helper/storageHelper'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import LocationViewInfoCards from './LocationViewInfoCards.vue'

export default {
  setup() {
    const { isStorageBooked } = storageHelper()
    const { countOfNotRentedStoragesinLocation } = storageHelper()
    const { countOfRentedStoragesinLocation } = storageHelper()

    return {
      isStorageBooked,
      countOfNotRentedStoragesinLocation,
      countOfRentedStoragesinLocation,
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardSubTitle,
    BListGroup,
    BListGroupItem,
    StatisticCardVertical,
    LocationViewInfoCards,
    BPopover,
    BBadge,
    BCardHeader,
    BCardBody,
  },
  props: ['id'],
  data() {
    return {
      windowHeight: window.innerWidth,
    }
  },
  computed: {
    locationEntry() {
      return this.$store.getters.locationById(Number(this.id))
    },
    getSortedStorages() {
      return this.$store.getters
        .getAllStorageFromLocationId(Number(this.id))
        .sort((a, b) => b.id - a.id)
    },
  },
  methods: {
    getVariant(storage) {
      if (this.isStorageBooked(storage).value) {
        return 'success'
      }
      return 'danger'
    },
    getStorageType(typeId) {
      return this.$store.getters.getStorageTypesById(Number(typeId))
    },
  },
}
</script>
<style>
.wide-popover {
  max-width: 400px;
}
.card.body {
  padding: 0.5rem !important;
}
</style>
