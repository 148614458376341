<template lang="">
  <div>
    <b-row class="match-height">
      <b-col
        xl="4"
        md="4"
        sm="4"
      >
        <statistic-card-vertical
          color="primary"
          icon="MapIcon"
          :statistic="`${locationEntry.name} (${locationEntry.shortName})`"
          statistic-title="Name (Kürzel)"
        />
      </b-col>
      <b-col
        xl="4"
        md="4"
        sm="4"
      >
        <statistic-card-vertical
          color="primary"
          icon="MapIcon"
          :statistic="locationEntry.city"
          statistic-title="Stadt"
        />
      </b-col>
      <b-col
        xl="4"
        md="4"
        sm="4"
      >
        <statistic-card-vertical
          color="primary"
          icon="MapIcon"
          :statistic="locationEntry.country"
          statistic-title="Land"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="6"
        md="6"
        sm="6"
      >
        <statistic-card-vertical
          color="primary"
          icon="MapIcon"
          :statistic="`${locationEntry.street} ${locationEntry.streetNumber}`"
          statistic-title="Straße"
        />
      </b-col>
      <b-col
        xl="6"
        md="6"
        sm="6"
      >
        <statistic-card-vertical
          color="primary"
          icon="MapIcon"
          :statistic="locationEntry.zipCode"
          statistic-title="PLZ"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BCardSubTitle,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardSubTitle,
    BListGroup,
    BListGroupItem,
    StatisticCardVertical,
  },
  props: {
    locationEntry: {
      type: Object,
      required: false,
    },
  },
}
</script>
<style lang=""></style>
